@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Inter:ital,wght@0,300;0,400;0,500;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
* {
  padding: 0;
  margin: 0;
  border: 0;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

:focus,
:active {
  outline: none;
}

a:focus,
a:active {
  outline: none;
}

aside,
nav,
section,
header,
footer {
  display: block;
}

html,
body {
  padding: 0;
  margin: 0;
  min-width: 320px;
  width: 100%;
  background: #000000;
  color: #dbd8e8;
  height: 100vh;
}

input,
button,
textarea {
  font-family: "Inter", sans-serif;
  outline: none;
}

input::-ms-clear {
  display: none;
}

button {
  cursor: pointer;
  transition: all 0.4s ease 0s;
}

button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

a,
a:visited {
  text-decoration: none;
  transition: all 0.4s ease 0s;
}

a:hover {
  text-decoration: none;
}

ul li {
  list-style: none;
}

img {
  vertical-align: top;
  max-width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: inherit;
  font-size: inherit;
}

body {
  line-height: normal;
  font-size: 15px;
  font-family: "Inter", sans-serif;
  position: relative;
  overflow-x: hidden;
  overflow-y: visible;
  font-weight: 400;
  padding: 6.5vh 0 10vh 0;
  display: flex;

  background-color: #000000;
  background-image: url(./images/bg.png);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.wrapper {
  width: 100%;
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  justify-content: space-between;
}

.container {
  max-width: 1170px;
  margin: 0 auto;
  padding: 0 20px;
  width: 100%;
}

.header,
.footer {
  flex: 0 0 auto;
}

.header__row {
  display: flex;
  align-items: center;
  filter: drop-shadow(2px 2px 0px #000000);
  -webkit-filter: drop-shadow(2px 2px 0px #000000);
}

.header__logo {
  max-width: 300px;
}

.header__center {
  max-width: 300px;
}

.header__right {
  margin-left: auto;
  display: flex;
  align-items: center;
}

.head-twitter {
  width: 50px;
  height: 50px;
  padding-right: 60px;
  transition: all 0.4s ease 0s;
  background-image: url(./images/icons-twitter-icon.png);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
.head-twiter:hover {
  background-image: url(./images/icons-twitter-icon.png);
}

.head-discord {
  width: 50px;
  height: 50px;
  padding-right: 60px;
  transition: all 0.4s ease 0s;
  background-image: url(./images/icons-discord-icon.png);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
.head-discord:hover {
  background-image: url(./images/icons-discord-icon.png);
}

/*.head-opensea {*/
/*  width: 45px;*/
/*  height: 45px;*/
/*  padding-right: 55px;*/
/*  transition: all 0.4s ease 0s;*/
/*  background-image: url(./images/icons-opensea-icon.png);*/
/*  background-size: contain;*/
/*  background-position: center;*/
/*  background-repeat: no-repeat;*/
/*}*/
/*.head-opensea:hover {*/
/*  background-image: url(./images/icons-opensea-icon.png);*/
/*}*/

.head-etherscan {
  width: 45px;
  height: 45px;
  padding-right: 55px;
  transition: all 0.4s ease 0s;
  background-image: url(./images/icons-etherscan-icon.png);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
.head-etherscan:hover {
  background-image: url(./images/icons-etherscan-icon.png);
}

.btn-head {
  margin-left: 12px;
  border-radius: 10px;
  background-color: #ca9f31;
  text-align: center;
  font-weight: 700;
  text-transform: uppercase;
  color: #ffffff;
  padding: 21px 30px;
  transition: all 0.4s ease 0s;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}
.btn-head:hover {
  color: #000000;
  background-color: #ca9f31;
}

.main {
  flex: 1 1 auto;
  padding-top: 2vh;
}
.main__row {
  display: flex;
  position: relative;
}
.main__left {
  padding-right: 2%;
  padding-top: 1%;
  width: 53%;
}
.main__right {
  width: 50%;
  position: relative;
  padding-bottom: 50%;
}
.main__right img {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.number {
  font-size: calc(30px + 30 * (100vw / 1920));
  color: #ffffff;
  font-weight: 800;
  filter: drop-shadow(2px 5px 0px #000000);
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #ffffff;
  -webkit-filter: drop-shadow(3px 3px 0px #000000);
}

.form-text {
  margin-top: 24px;
  font-size: 24px;
  color: #ffffff;
  font-weight: 700;
  filter: drop-shadow(2px 2px 0px #000000);
  -webkit-filter: drop-shadow(2px 2px 0px #000000);
}

.dfs-yellow{
  color: #ca9f31;
}

.form {
  margin-top: 24px;
}

.form-row {
  display: flex;
  align-items: center;
}

.in-form {
  width: calc(350px + 30 * (100vw / 1920));
  border-radius: 10px;
  background-color: #ffffff;
  height: 50px;
  padding: 0 26px;
  color: #000000;
  font-size: 15px;
  font-weight: 500;
  transition: all 0.4s ease 0s;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  filter: drop-shadow(0px 0px 2px #ca9f31);
  -webkit-filter: drop-shadow(0px 0px 2px #ca9f31);
}
.in-form::placeholder, .in-form::-moz-placeholder, .in-form::-webkit-placeholder {
  color: #4d2bab;
  font-size: 15px;
  font-weight: 500;
}
.in-form:focus {
  background: #ffffff;
}

.btn-form {
  height: 50px;
  text-align: center;
  border-radius: 10px;
  background-color: #ca9f31;
  padding: 2px 40px;
  transition: all 0.4s ease 0s;
  margin-left: 10px;
  font-size: 15px;
  font-weight: 700;
  text-transform: uppercase;
  color: #111111;
  filter: drop-shadow(0px 0px 2px #ca9f31);
  -webkit-filter: drop-shadow(0px 0px 2px #ca9f31);
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}
.btn-form:hover {
  color: #000000;
  background-color: #ca9f31;
}

.text-bot-form {
  margin-top: 35px;
  color: #e7e7e7;
  max-width: 400px;
  line-height: 20px;
}

.footer {
  margin-top: 40px;
}
.footer__row {
  display: flex;
  align-items: center;
  color: #ffffff;
  font-weight: 700;
  filter: drop-shadow(2px 2px 0px #000000);
  -webkit-filter: drop-shadow(2px 2px 0px #000000);
}

.link-follow {
  color: #d6d4e1;
  font-weight: 700;
  transition: all 0.4s ease 0s;
}

.for-right {
  margin-left: auto;
}

.link-fot {
  font-size: 15px;
  font-weight: 500;
  color: #d6d4e1;
  text-transform: uppercase;
  transition: all 0.4s ease 0s;
}
.link-fot:hover {
  color: #ffffff;
}
.link-fot:first-child {
  margin-right: 102px;
}

.row {
  display: flex;
}

.align-items-center {
  align-items: center;
}

.justify-content-center {
  justify-content: center;
  justify-content: space-between;
}

.justify-content-between {
  justify-content: space-between;
}

.none {
  display: none !important;
}

.d-flex {
  display: flex;
}

.body-fixed {
  position: fixed;
}

.text-center {
  text-align: center;
}

.noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
}

.ibg {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.ibg img.ibg-img {
  display: none;
}

li.counter {
  list-style-type: none;
  counter-reset: item;
}
li.counter li {
  position: relative;
  padding: 0px 0px 0px 45px;
}
li.counter li:before {
  counter-increment: item;
  content: counter(item);
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  color: #818181;
  font-size: 14px;
  font-weight: 700;
  text-align: center;
  line-height: 26px;
  border-radius: 50%;
  width: 28px;
  height: 28px;
  border: 1px solid #ca9f31;
}

.video {
  position: relative;
  overflow: hidden;
  height: 0;
  padding-bottom: 56.25%;
}
.video video,
.video iframe,
.video object,
.video embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.videobg video,
.videobg iframe,
.videobg object,
.videobg embed {
  position: fixed;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: -100;
  transform: translateX(-50%) translateY(-50%);
  background-size: cover;
}

.img-responsive {
  display: block;
  max-width: 100%;
  height: auto;
}

input.error,
textarea.error {
  border-color: red;
}

@media (max-width: 1599px) {
  .main__left {
    width: 60%;
  }

  .main__right {
    width: 40%;
    padding-bottom: 40%;
  }
}
@media (max-width: 1299px) {
  .main__left {
    width: 62%;
  }

  .main__right {
    width: 38%;
    padding-bottom: 38%;
  }
}
@media (max-width: 1023px) {
  .form-text {
    font-size: 16px;
    margin-top: 10px;
  }

  .form {
    margin-top: 10px;
  }

  .text-bot-form {
    margin-top: 10px;
  }

  .header__logo {
    max-width: 250px;
  }

  .main {
    align-items: center;
    display: flex;
  }
}
@media (max-width: 991px) {
  .main__left {
    width: 100%;
  }

  .main__right {
    position: absolute;
    width: 12%;
    padding-bottom: 12%;
    right: 0;
  }

  .main__right2 {
    position: absolute;
    width: 25%;
    height: 25%;
    padding-bottom: 12%;
    right: 0;
  }

  .footer {
    padding-bottom: 20px;
  }

  .btn-head {
    padding-top: 17px;
    padding-bottom: 17px;
  }
}
@media (max-width: 650px) {
  .main {
    padding-top: 4%;
    display: flex;
    align-items: center;
  }

  .main__left {
    padding-top: 4%;
    padding-right: 0;
  }

  .header__row {
    flex-direction: column;
    align-items: center;
  }

  .header__right {
    margin: 20px auto 0 auto;
  }

  .form-row {
    flex-direction: column;
    align-items: center;
    padding-top: 10px;
  }

  .btn-form {
    margin: 20px auto 0 auto;
  }

  .in-form {
    width: 100%;
  }

  .text-bot-form {
    margin: 20px 0 0 0;
    max-width: 100%;
    text-align: center;
  }

  .footer__row {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .for-right {
    margin: 20px 0 20px 0;
  }
}
